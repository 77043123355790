import * as React from "react"
import HeaderItem from "../components/header-item"
import Menu from "../components/menu"
import Footer from "../components/footer"
import Seo from "../components/seo"
import FilterItem from "../components/filter-item"
import { StaticImage } from "gatsby-plugin-image"
import ProjectThumbnail from "../components/project-thumbnail"

// markup
const IndexPage = () => {

  const thumbnailImageAttributes = {
    className: "w-4/6 md:w-full",
    placeholder: "blurred",
    loading: "eager"
  };

  return (
    <div>
      <Seo />
      <Menu/>
      <div className="max-w-screen-xl mx-auto mt-12 px-5">
        <h1 className="font-serif text-xl font-medium text-battleship-grey text-center">
          Hi, I am Charlotte. I create.
        </h1>
        <div>
          <HeaderItem>I am a curious Web Designer,<br className="block sm:hidden"/> based in Brussels, Belgium.</HeaderItem>
          <HeaderItem>Have a look at my latest work <br className="block sm:hidden"/>and don't hesitate to contact me.</HeaderItem>
        </div>
      </div>
      <div className="max-w-screen-xl mt-12 px-5 mx-auto text-center md:text-left"> 
        <FilterItem active to="/">All</FilterItem>
        <FilterItem to="/work-uxui-design">UX/UI Designs</FilterItem>
        <FilterItem to="/work-product-design">Product Designs</FilterItem>
        <FilterItem to="/work-logo">Logos</FilterItem>
        <FilterItem to="/work-print">Print</FilterItem>
      </div>
      <div className="max-w-screen-xl mx-5 xl:mx-auto grid-cols-1 md:grid md:grid-cols-3 md:grid-rows-3 md:gap-4 pt-4">
      <ProjectThumbnail to="/detail-mb" title="Product Design - MyBroker">
        <StaticImage 
          {...thumbnailImageAttributes}
          src="../images/mb-mockup01.png"
        />
      </ProjectThumbnail>

      <ProjectThumbnail to="/detail-bm" title="Product Design - BrioMobile">
        <StaticImage 
          {...thumbnailImageAttributes}
          src="../images/bm-mockup.png"
        />
      </ProjectThumbnail>
    
      <ProjectThumbnail to="/detail-ls" title="Print - Event Invitation">
        <StaticImage 
          {...thumbnailImageAttributes}
          src="../images/ls-mockup.png"
        />
      </ProjectThumbnail>
        
      <ProjectThumbnail to="/detail-data" title="Logo - Data Wizards" >
        <StaticImage 
          {...thumbnailImageAttributes}
          src="../images/dw-mockup.png"
        />
      </ProjectThumbnail>

      <ProjectThumbnail to="/detail-jb" title="UX/UI Design - Website Redesign">
        <StaticImage 
          {...thumbnailImageAttributes}
          src="../images/jb-mockup.png"
        />
      </ProjectThumbnail>
        
      <ProjectThumbnail to="/detail-fs" title="Logo - F.S Business Management">
        <StaticImage 
          {...thumbnailImageAttributes}
          src="../images/fs-mockup.png"
        />
      </ProjectThumbnail>
       
      <ProjectThumbnail to="/detail-tc" title="UX/UI Design - Travel Planning App">
        <StaticImage 
          {...thumbnailImageAttributes}
          src="../images/tc-mockup.png"
        />
      </ProjectThumbnail>

      <ProjectThumbnail to="/detail-elog" title="UX/UI Design - Time Tracking SaaS">
        <StaticImage 
          {...thumbnailImageAttributes}
          src="../images/elog-mockup.png"
        />
      </ProjectThumbnail>
       
      <ProjectThumbnail to="/detail-hopper" title="UX/UI Design - Add a Feature">
        <StaticImage 
          {...thumbnailImageAttributes}
          src="../images/hopper-mockup.png"
        />
      </ProjectThumbnail>
    
      <ProjectThumbnail to="/detail-izyfit" title="UX/UI Design - Wellness App">
        <StaticImage 
          {...thumbnailImageAttributes}
          src="../images/izyfit-mockup.png"
        />
      </ProjectThumbnail>
      
      </div>
      <Footer/>
    </div>
     
  
  )
}


export default IndexPage
